/**
 =========================================================

 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Kit 2 React React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 6. The `component` key is used to store the component of its route.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
 a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
 you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
 */

// @mui material components
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";
import ContactPageIcon from '@mui/icons-material/ContactPage';

// Pages
import Solutions from "layouts/pages/landing-pages/solutions";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import About from "layouts/pages/landing-pages/about";
import SignIn from "layouts/pages/authentication/sign-in";

// Sections

const routesForMultipleRedirection = [
  {
    name: "BKE Digital",
    icon: <HomeIcon/>,
    route: "/hakkımızda",
    multiplePath: false,
    component: <About />,
    key : 1
  },
  {
    name: "Çözümlerimiz",
    icon: <AspectRatioIcon/>,
    multiplePath: true,
    route: "/çözümlerimiz",
    routeArray: ["çözümlerimiz","e-ihracat-danışmanlığı","e-ticaret-danışmanlığı","arayüz-tasarımı-geliştirme","pazaryeri-entegrasyonları-desteği","e-ticaret-site-kurulumu","digital-pazarlama-yönetimi","sosyal-medya-yönetimi","seo","cdn","reklam-yönetimi"],
    component: <Solutions />,
    key : 2
  },
  {
    name: "İletişim",
    icon: <InfoIcon />,
    multiplePath: false,
    route: "/iletişim",
    component: <ContactUs />,
    key : 3
  },

];

export default routesForMultipleRedirection;

/**
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routesForMultipleRedirection from "routesForMultipleRedirection";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }

        if (route.multiplePath == true) {

            return route.routeArray.map((path, index) => {
                    return <Route exact path={path} element={route.component} key={route.key}/>;
                }
            )

        } else {
            return <Route exact path={route.route} element={route.component} key={route.key}/>;
        }

        return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>

          { getRoutes(routesForMultipleRedirection)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/team/e-ihracat_dansmanlg.jpg";
import team2 from "assets/images/team/arayüz tasarım geliştirme.jpg";
import team3 from "assets/images/team/pazaryeri entegrasyonları desteği.jpg";
import team4 from "assets/images/team/e-ticaret site kurulumu.jpg";
import team5 from "assets/images/team/dijital pazarlama.jpg";
import team6 from "assets/images/team/sosyal medya yönetimi.jpg";
import team7 from "assets/images/team/seo yönetimi.jpg";
import team8 from "assets/images/team/cdn yönetimi.JPG";
import team9 from "assets/images/team/reklam yönetimi.jpg";
import team10 from "assets/images/team/e-ticaret yönetimi.jpg";

function AboutDetail() {
  return (
      <MKBox
          component="section"
          variant="gradient"
          bgColor="white"
          position="relative"
          py={6}
          px={{xs: 2, lg: 0}}
          mx={-2}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} md={8} sx={{mb: 6}}>
              <MKTypography variant="h3">
                Takım Çalışmalarımız
              </MKTypography>
              <MKTypography variant="body2" opacity={0.8}>
                Hedefimiz profesyonel ve alanında uzman kadromuzla bütün e-ticaret ihtiyaçlarınızı karşılamak
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <HorizontalTeamCard
                    image={team1}
                    name="E-İhracat Danışmanlığı"
                    position={{color: "info"}}
                    description="BKE Dijital olarak verdiğimiz eğitimler ile hedef pazarlarda en kısa zamanda, en az maliyetle, en doğru müşteriye ulaşmanızı ve optimum sonuçları almanıza yardımcı oluruz."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <HorizontalTeamCard
                    image={team2}
                    name="Arayüz Tasarımı/Geliştirme"
                    position={{color: "info"}}
                    description="T-soft- Ticimax–İdeasoft gibi öncü e-ticaret altyapılarına sitenize uygun arayüz tasarımları yapıyor ve kodluyoruz. Sonrasında yönetim panelinde ihtiyacınız doğrultusunda her türlü sorununuza tam destek oluyoruz. "
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={{xs: 1, lg: 0}}>
                <HorizontalTeamCard
                    image={team3}
                    name="Pazaryeri Entegrasyonları Desteği"
                    position={{color: "info"}}
                    description="Pazaryerleri ile alakalı ürün yükleme, stok takibi, kategori eşleme, marka eşleme, ürün listeleme, sipariş entegrasyonları gibi süreçleri tamamlayıp takibini yapıyoruz."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={{xs: 1, lg: 0}}>
                <HorizontalTeamCard
                    image={team4}
                    name="E-Ticaret Site Kurulumu"
                    position={{color: "info"}}
                    description="E-Ticaret sitenizin kurulumu ve sonrası tüm süreçlerin yönetimini tüm detaylarıyla yönetiyoruz. Henüz bir web siten yoksa hemen bizimle iletişime geç lider markalardan ayrıcalıklı fiyat tekliflerimizi incele."
                />
              </MKBox>
            </Grid>


            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} lg={6}>
                <MKBox mb={{xs: 1, lg: 0}}>
                  <HorizontalTeamCard
                      image={team8}
                      name="CDN yönetimi"
                      title="CDN (Content Delivery Network) Nedir?"
                      description2="İçerik dağıtım ağı olarak adlandırılan CDN (Content Delivery Network), coğrafi olarak dağıtılmış, birbirine bağlı bir sunucu grubudur. Dağıtımını hızlandırmak için bir kullanıcıya en yakın ağ konumundan önbelleğe alınmış internet içeriği sağlar. Bu da web sayfalarının hızlı açılmasını beraberinde getirir. Dolayısıyla kullanıcı aradığı bilgiye, ürüne, hizmete veya farklı türde bir unsura kısa sürede erişebilir. 
CDN’nin birincil amacı, ziyaretçilere içerik ve zengin medya unsurlarını göstermek için gereken süreyi azaltarak web performansını iyileştirmektir. CND mimarisi, trafiğin uzun mesafelerde ve çeşitli ağlarda taşınmasından kaynaklanan ağ gecikmesini önlemek için tasarlanmıştır. Özellikle son dönemlerde mobil trafiğin artmasıyla birlikte web siteleri daha dinamik içerik, video ve hizmet yazılımları içerdiğinden; bu gecikmeyi ortadan kaldırmak önemlidir.
CDN sağlayıcıları, önbelleğe alınmış içeriği ya kendi ağ varlık noktalarında (POP’lar) ya da üçüncü taraf veri merkezi/merkezlerinde barındırır. Ziyaretçi bir web sayfasını ziyaret ederek içerik talep ettiğinde, içerik bir CDN’de önbelleğe alınmışsa, isteği ziyaretçiye en yakın sunucuya yönlendirir."
                      position={{color: "info"}}
                      description="Web site hızınızı artırmak için cdn kullanımı kaçınılmaz oluyor. Cdn yönetimi ile birlikte fotoğraflarınızda webp optimizasyonu yaparak site performansınızı yükseltiyoruz."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid>
                  <MKBox mb={{xs: 1, lg: 0}} mt={10}>
                    <HorizontalTeamCard
                        image={team9}
                        name="Reklam Yönetimi"
                        position={{color: "info"}}
                        description="Dijital dünyada iz bırakmanız için gereken dijital pazarlama faaliyetlerinden bir tanesi olan reklamların stratejisini oluşturmak, ihtiyaçlarınız doğrultusunda planlama yapmak ve maksimum faydayı sağlamanız için çalışıyoruz. "
                    />
                  </MKBox>
                </Grid>
                <Grid>
                  <MKBox mb={{xs: 1, lg: 0}} mt={10}>
                    <HorizontalTeamCard
                        image={team10}
                        name="E-Ticaret Danışmanlığı"
                        position={{color: "info"}}
                        description="E-Ticaret sitesinin kurulumu sonrası tüm süreçlerin yönetimini her biri alanında uzman ekibimizle yönetiyoruz."
                    />
                  </MKBox>

                </Grid>
                <Grid>
                  <MKBox mb={{xs: 1, lg: 0}} mt={10}>
                    <HorizontalTeamCard
                        image={team6}
                        name="Sosyal Medya Yönetimi"
                        position={{color: "info"}}
                        description="Pazarlama, bizim ana fonksiyonumuzdur. Mümkün olan en fazla kişinin dikkatini çekmek için çalışırken, tüm sosyal ağ sitelerinde markanızın varlığını yönetiyoruz.Markanıza özel hazırladığımız içerikler ile eksiksiz bir sosyal medya yönetim stratejisine sahip olmanızı sağlıyoruz."
                    />
                  </MKBox>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={12} lg={6}>
              <MKBox mb={{xs: 1, lg: 0}}>
                <HorizontalTeamCard
                    image={team7}
                    name="SEO"
                    position={{color: "info"}}
                    title="SEO nedir?"
                    description2="SEO (Arama Motoru Optimizasyonu), web sitelerini arama motorları için optimize etme uygulamasıdır. Başka bir şekilde tanımlamak gerekirse: Arama Motoru Optimizasyonu (SEO), bir anahtar kelime için gerçekleşen aramada web sitemizin en iyi sonuçlar içerisinde yer alması için optimize edilme sürecidir."
                    description="İşletmelerin ihtiyaçları doğrultusunda doğru stratejiler kurgulayarak etkileşim sağlayacak güçlü içerikler oluşturuyoruz. Sunduğumuz içerik hizmeti ve farklı stratejiler ile size katkı sağlıyoruz."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={{xs: 1, lg: 0}}>
                <HorizontalTeamCard
                    image={team5}
                    name="Dijital Pazarlama Yönetimi"
                    position={{color: "info"}}
                    description="Trafiğinizi, satışlarınızı, karlılığınızı artıran dijital reklam kampanyaları oluşturuyor, marka bilinirliğinizi artıyor ve efektif şekilde yönetiyoruz."
                    title={"Dijital Pazarlama Nedir?"}
                    description2="
Dijital pazarlama tüm çevrimiçi pazarlama çabalarınız için genel bir terimdir. İşletmeler, mevcut ve potansiyel müşterileri ile bağlantı kurmak için Google arama, sosyal medya, e-posta ve web siteleri gibi dijital kanalları kullanırlar"
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default AboutDetail;

/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/anasayfa_en_alt_banner.jpg";
import tsoftLogo from "assets/images/tsoft-logo.png";
import reactLogo from "assets/images/react-logo.svg";

function Download() {
  return (
      <MKBox component="section" py={{xs: 0, sm: 12}}>
        <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            sx={{overflow: "hidden"}}
        >
          <MKBox
              component="img"
              src={bgImage}
              alt="pattern-lines"
              position="absolute"
              top={0}
              left={0}
              width="100%"
              zIndex={1}
              opacity={0.2}
          />
          <Container sx={{position: "relative", zIndex: 2, py: 12}}>
            <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
              <MKTypography variant="h3" color="white">
                Site Trafiği Yönetimini sitenizde aktif yapabiliyor musunuz?
              </MKTypography>
              <MKTypography variant="body2" color="white" mb={6}>
                Site ziyaretçilerinize doğru zamanda, doğru içerikleri sunarak dijital süreçlerinizi takip edin. Siparişlerinizin tamamlanması sürecinde
                müşterilerinizin hangi sayfalarda ne kadar süre harcadığını, nerede vazgeçtiğinin analizini takip edip iyileştirmeler yapmanızı sağlayalım
              </MKTypography>

            </Grid>
          </Container>
        </MKBox>
        <Container>
          <Grid container item xs={6} mx="auto">
            <MKBox textAlign="center">
              <MKTypography variant="h3" mt={6} mb={3}>
                Altyapı hizmeti verilen teknolojiler
              </MKTypography>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={4} lg={2}>
                  <Tooltip title="T-Soft altyapısı">
                    <MKBox
                        component="a"
                        target="_blank"
                    >
                      <MKBox
                          component="img"
                          src={tsoftLogo}
                          width="100%"
                      />
                    </MKBox>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} lg={2}>
                  <Tooltip title="VueJs ile arayüz geliştirmeleri">
                    <MKBox
                        opacity={0.5}
                        component="a"
                        href="#"
                        target="_blank"
                        onClick={(e) => e.preventDefault()}
                    >
                      <MKBox
                          component="img"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/vue.jpg"
                          width="100%"
                      />
                    </MKBox>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} lg={2}>
                  <Tooltip title="AngularJs ile arayüz tasarımları">
                    <MKBox
                        opacity={0.5}
                        component="a"
                        href="#"
                        target="_blank"
                        onClick={(e) => e.preventDefault()}
                    >
                      <MKBox
                          component="img"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/angular.jpg"
                          width="100%"
                      />
                    </MKBox>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} lg={2}>
                  <Tooltip title="ReactJs ile arayüz tasarımı">
                    <MKBox
                        component="a"
                        href="https://www.creative-tim.com/product/material-kit-react"
                        target="_blank"
                    >
                      <MKBox
                          component="img"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/react.jpg"
                          width="100%"
                      />
                    </MKBox>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} lg={2}>
                  <Tooltip title="Sketch ve Zeplin ile UX çalışmaları">
                    <MKBox
                        opacity={0.5}
                        component="a"
                        href="#"
                        target="_blank"
                        onClick={(e) => e.preventDefault()}
                    >
                      <MKBox
                          component="img"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/sketch.jpg"
                          width="100%"
                      />
                    </MKBox>
                  </Tooltip>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default Download;

/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import AboutSection from "pages/LandingPages/About/sections/AboutSection";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/about_banner.jpg";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import footerRoutes from "../../../footer.routes";

function About() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox bgColor="white">
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                            `${linearGradient(
                                rgba(gradients.custom.main, 0.2),
                                rgba(gradients.custom.state, 0.3)
                            )}, url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                />
                <Card
                    sx={{
                        p: 2,
                        mx: {xs: 2, lg: 3},
                        mt: -8,
                        mb: 4,
                        backgroundColor: ({palette: {white}, functions: {rgba}}) => rgba(white.main, 0.8),
                        backdropFilter: "saturate(200%) blur(30px)",
                        boxShadow: ({boxShadows: {xxl}}) => xxl,
                    }}
                >
                    <AboutSection/>

                </Card>

                <MKBox pt={6} px={1} mt={6}>
                    <DefaultFooter content={footerRoutes}/>
                </MKBox>
            </MKBox>
        </>
    );
}

export default About;

// @mui icons
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "BKE Digital",
    image: logoCT,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "Hizmetlerimiz",
      items: [

        {name: "E-İhracat Danışmanlığı", route: "/e-ihracat-danışmanlığı"},
        {name: "E-Ticaret Danışmanlığı", route: "/e-ticaret-danışmanlığı"},
        {name: "Arayüz Tasarımı/Geliştirme", route: "/arayüz-tasarımı-geliştirme"},
        {name: "Pazaryeri Entegrasyonları Desteği", route: "/pazaryeri-entegrasyonları-desteği"},
        {name: "E-Ticaret Site Kurulumu", route: "/e-ticaret-site-kurulumu"},
        {name: "Dijital Pazarlama Yönetimi", route: "/digital-pazarlama-yönetimi"},
        {name: "Sosyal Medya Yönetimi", route: "/sosyal-medya-yönetimi"},
        {name: "Seo", route: "/seo"},
        {name: "CDN yönetimi", route: "/cdn"},
        {name: "Reklam Yönetimi", route: "/reklam-yönetimi"},
      ],
    },
    {
      name: "İletişim/Destek",
      items: [
        {name: "Erenler Mah. 1201 Sok. No:30 ", route: "/iletişim"},
        {name: "Erenler / Sakarya", route: "/iletişim"},
        {name: "Tel: +90 531 270 95 10", route: "/iletişim"},
      ],
    },
    {
      name: "legal",
      items: [
        {name: "terms & conditions", href: ""},
        {name: "privacy policy", href: ""},
        {name: "licenses", href: ""},
      ],
    },
  ],
  copyright: (
      <MKTypography variant="button" fontWeight="regular">
        All rights reserved. Copyright &copy; {date} Material Kit by{" "}
        <MKTypography
            component="a"
            href="https://www.creative-tim.com"
            target="_blank"
            rel="noreferrer"
            variant="button"
            fontWeight="regular"
        >
          Creative Tim
        </MKTypography>
        .
      </MKTypography>
  ),
};

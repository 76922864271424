/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/tsoft-tam-destek.jpg";
function BuiltByDevelopers() {


  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0),
            rgba(gradients.dark.state, 0)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            BKE Digital olarak
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1} sx={{fontSize: 25}}>
              T-soft müşterilerine özel tam destek
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2} sx={{fontSize:17}}>
              T-soft yönetim panelinde sizlere gelişmiş destek sunuyoruz.
              Sitenizi geliştirmek için sınırsız önerilerde bulunuyor ve tüm sorunlarınıza çözüm üretiyoruz.
          </MKTypography>

        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;

/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/bruce-mars.jpg";

function AboutSection() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">

          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Biz kimiz? <br/></MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
                  <br/>
                  BKdijital 2015 yılından beri dijital pazarlama ve performans, stratejik planlama, yazılım danışmanlığı ve grafik tasarım, seo, pazaryeri satış planlaması ve entegrasyonları, arayüz tasarımı, reklam yönetimi gibi konularda uzmanlaşmış, işini en hızlı ve sağlam şekilde yapmayı hedeflemiş daima çözüm odaklı bir dijital ajanstır.
<br/><br/>
                  <b>Operasyonel faaliyetler</b>
                  <br/>
                  <br/>
                  Danışmanlıklarla sınırlı kalmayı, sizin için gerekli operasyonu da biz yürütüyoruz.
                  Bir projeyi bütünüyle ele alıp çözüm odaklı yapımızla tüm eksikleri gidiyoruz ve güncel kalıyoruz. Somut, ölçülebilir ve performansa dayalı işler yapıyoruz.
                  <br/><br/>
                   <b>Gelişmiş destek hizmetlerimiz</b>
                  <br/><br/>
                  E-ticaret sitenizdeki; toplu ürün girişleri, banner tasarımları ve değişiklikleri, kategori ağacının oluşturulması, kargo entegrasyonları, ödeme entegrasyonları, fatura entegrasyonları, muhasebe entegrasyonları, kampanya önerileri, sms ve e-mail entegrasyonları vb. gibi oluşabilecek tüm ihtiyaçlarınızı yönetip farkımızı ortaya koyuyoruz.
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutSection;

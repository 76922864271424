/*
=========================================================

=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/main/main_banner.jpeg";
import Team from "../LandingPages/Solutions/sections/Team";

function Presentation() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid item xs={12} lg={12} justifyContent="left">
                        <MKTypography
                            variant="h2"
                            color="white"
                            mt={-6}
                            mb={1}
                            ml={-10}
                            fontWeight={"bold"}
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down("xs")]: {
                                    fontSize: size["1xl"]
                                },
                                ml: {xs: 1, md: -6, lg: -10},
                                mt: {xs:10},
                                fontSize: {xs: 23 ,md: 35, lg: 35}
                            })}
                        >
                            GELİŞEN TEKNOLOJİDE <br/> YENİLİĞİ YAKALA{" "}
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: {xs: 2, lg: 3},
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({palette: {white}, functions: {rgba}}) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({boxShadows: {xxl}}) => xxl,
                }}
            >
                <Counters/>


                <Team/>
                <Container sx={{mt: 6}}>
                    <BuiltByDevelopers/>
                </Container>

                <Testimonials/>
                <Download/>

            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default Presentation;
